/* DEFAULT DESKTOP/LAPTOP MODE */
.nav-bar {
    display: flex;
    justify-content: space-between;
    width: 100vw;
    height: 8rem;
    padding: 0 7rem 0 5rem;
    background: #f6de98;
    box-sizing: border-box;
    border-color: #24347b; border-style: solid none; border-width: 1.3rem;
    z-index: 15; position: fixed; top: -50%;
    transition: all 0.45s cubic-bezier(.53, 0, .55, 1);
}

.nav-bar__active {
    top: 0%;  
}

.nav-bar button, .nav-bar__active button {
    background: none;
    border: none;
    cursor: pointer;
}

/* nav-bar logo */
.nav-bar img, .nav-bar__active img {
    height: 100%;
    pointer-events: none;
}

.nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 60%;
    height: 100%;
}

.nav-mobile-container {
    display: none;
}

.nav-link {
    text-decoration: none;
}

.nav-link p {
    color: #24347b;
    font-family: 'DM Sans';
    font-size: min(6vw, 1.75rem);
    transition: 0.2s;
}

.nav-link:hover p {
    color: #7989d2;
    transition: 0.2s;
    font-size: min(6.5vw, 2.1rem);
}

/* TABLET MODE IN LANDSCAPE */
@media screen and (max-width: 1200px) {
    .nav-bar {
        padding: 0 3rem;
    }
    .nav-container {
        width: 80%;
    }
}

/* TABLETS AND PHONES */
@media screen and (max-width: 850px) {
    .nav-bar__active {
        padding: 0 1rem;
        height: 5rem;
        border-width: 0.5rem;
    }
    .nav-container {
        display: none;
    }
    .nav-mobile-container {
        display: block;
    }
}

@media screen and (max-height: 600px) {
    .nav-link p {
        font-size: 1.5rem;
    }
    .nav-bar__active {
        padding: 0 1rem;
        height: 4rem;
        border-width: 0.5rem;
    }
}