.nav-links {
    box-sizing: border-box;
    padding: 3rem 0;
    position: fixed; /* fixed instead of absolute to fix the issue of scroll down the page while in sidebar, then sidebar closing to the top right corner instead of straight to the right */
    top: 0;
    width: 70%;
    height: 100vh;
    left: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #24347b;
    overflow-y: auto;
}

.nav-link__mobile {
    text-decoration: none;
    margin: 0;
    height: 20vh;
    box-sizing: border-box;
    padding-top: calc(10vh - min(6vw, 1.75rem)); /* 10vh - font-size, to center the paragraph within this div. Not using flexbox because safari giving issues. */
    display: flex;
    flex-direction: row;
}

.nav-link__mobile p {
    color: #f6de98;
    font-family: 'DM Sans';
    font-size: min(6vw, 1.75rem);
    transition: 0.2s;
    margin: 0;
}

.nav-link__mobile p:hover{
    color: #ffcb2f;
}

.nav-mobile-toggle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    right: 2rem;
    z-index: 1;
    -webkit-user-select: none;
    user-select: none;
    height: 100%;
}

.nav-mobile-toggle input {
    position: absolute;
    display: flex;
    height: 80%;
    width: 5rem;
    cursor: pointer;
    opacity: 0;
    z-index: 2;
    margin: 0;
}

.nav-mobile-toggle span {
    display: flex;
    width: 2rem;
    height: 3px;
    margin: 2px 0;
    border-radius: 3px;
    position: relative;
    background: #24347b;
    z-index: 1;
    transform-origin: 19%;
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease;
}

.nav-mobile-toggle input.checked ~ span:nth-last-child(3) {
    transform: rotate(45deg);
    height: 3px;
    background: #e5d196;
}
.nav-mobile-toggle input.checked ~ span:nth-last-child(2) {
    opacity: 0;
}

.nav-mobile-toggle input.checked ~ span:nth-last-child(1) {
    transform: rotate(-45deg);
    height: 3px;
    background: #e5d196;
}

@keyframes slideIn {
    0% {
        left: 100vw;
    }
    100% {
        left: 30vw;
    }
}

@keyframes slideOut {
    0% {
        left: 30vw;
    }
    100% {
        left: 100vw;
    }
}

.inView {
    animation: slideIn 0.4s ease-in-out;
    left: 30vw;
}
.outOfView {
    animation: slideOut 0.4s ease-in-out;
    left: 100vw;
}