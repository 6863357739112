.about-us {
    margin-bottom: 4rem;
    align-items: center;
}

.about-us__team {
    text-align: center;
}

.about-us__header {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0;
}

.member-box {   
    display: inline-block;
    justify-content: center;
    vertical-align: top;
    margin: min(6vw, 3rem);
    margin-bottom: 7rem;
}

.member-box .member-pic {
    width: min(30vw, 10rem);
    border-radius: 50%;    
    box-shadow: 0 0 0 min(1vw, 0.3rem) #ff9071;
    pointer-events: none;
}

.member-box:nth-child(3n + 2) .member-pic {
    box-shadow: 0 0 0 min(1vw, 0.3rem) #86a8ff;
}

.member-box:nth-child(3n) .member-pic {
    box-shadow: 0 0 0 min(1vw, 0.3rem) #53af53;
}

/* Colours given by request */
.member-box .akshat-colour {
    box-shadow: 0 0 0 min(1vw, 0.3rem) #008cff;
}

.member-box .vanda-colour {
    box-shadow: 0 0 0 min(1vw, 0.3rem) rgb(206, 201, 101);
}

.member-box p {
    font-family: 'DM Sans Light';
}

.social-media-logo {
    transition: all 0.2s ease-out;
    height: 3rem;
    margin: min(2vw, 0.5rem);
    border-radius: 50%;    
    box-shadow: 0 0 0 min(1vw, 0.2rem) #ff71ee;
}

.social-media-logo:hover {
    transition: all 0.2s ease-out;
    box-shadow: 0 0 0 min(1vw, 0.2rem) #ff7171;    
    transform: scale(1.15);
}

/* Larger Monitor Enviornment */
@media screen and (min-width: 1000px){
    .member-box {   
        max-width: min(30vw, 10rem);
    }
    .about-us__team {
        margin-left: 7vw; margin-right: 7vw;
    }
}
