@font-face {
  font-family: "DM Sans Bold";
  src: url('./assets/fonts/DMSans-Bold.ttf');
}

@font-face {
  font-family: "DM Sans";
  src: url('./assets/fonts/DMSans-Medium.ttf');
}

@font-face {
  font-family: "DM Sans Light";
  src: url('./assets/fonts/DMSans-Regular.ttf');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Custom Background */
.custom-background {
  background: linear-gradient(90deg, #F6DE98, #0e31bcd6);
  display: flex;
  flex-direction: column;
}

/* Background behind navbar on home page for spacing */
.navbar-spacer {
  background: rgb(36, 52, 123);
  height: 8rem;
}

.section-title {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}

.home, .about-us, .events, .join-us, .resources {
  padding: min(10vh, 5rem) min(6vw, 2rem);
  margin-bottom: 5vh;
  background-color: #fff7de;
  display: grid;
  margin: 5rem 10vw 0 10vw;
  border-radius: 3vw;
  display: flex;
  flex-direction: column;
  box-shadow: 0.75rem 0.75rem 0 rgba(0, 0, 0, 0.3);
  align-items: center;
}

@media screen and (max-width: 1000px){
  .home, .about-us, .events, .join-us, .resources {
    margin-left: 5vw; margin-right: 5vw;
  }
}

@media screen and (max-width: 850px) {
  .navbar-spacer {
    height: 5rem;
  }
}

a:hover {
  color: hsl(271, 68%, 47%);
  text-decoration: none;
}