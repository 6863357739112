.dropdown {
    width: 100%;
}

/* Header */
.dropdown__header {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #24347b;
    cursor: pointer;
    transition: background-color 0.2s;
}

.dropdown__header:hover {
    background-color: hsl(229, 55%, 26%);
}

.dropdown__header__caret {
    height: 100%;
    display: flex;
    align-items: center; /* Center the caret vertically */
    position: absolute;
    left: 2rem;
}

.dropdown__header__caret img {
    transform: rotate(0);
    transform-origin: 50% 50%; 
    transition: transform 0.6s cubic-bezier(.2,2,1,1);
}

.dropdown__header__caret.open img {
    transform: rotate(90deg);
}

.dropdown__header__caret img {
    height: 50%;
    max-height: 1.9rem;
}

.dropdown__header__title {
    text-align: center;
}

.dropdown__header__title h3 {
    color: #fcb839;
    display: flex;
    align-items: center;
    margin-left: 3.5rem; /* Ensure logo doesn't go overlap caret */
    margin-right: 3.5rem; /* Even spacing so text remains centered */
}

/* Content */
.dropdown__content {
    background-color: #525d92;
    padding: 0;
    box-sizing: border-box;
    position: relative; /* To allow for absolute positioning of the map controls */
}

.dropdown__content ul {
    padding: 1rem 7% 1rem 10%;
    margin: 0;
}

.dropdown__content.closed-ul {
    max-height: 0;
    overflow: hidden;
    transition: max-height 2s cubic-bezier(0, 2, 1, 1);
}

.dropdown__content.open-ul {
    max-height: 100rem; /*Because css is funky I have to set a max-height greater than the list will ever get for the transition property to work*/
    margin: 0;
    transition: max-height 0.5s ease-in;
}

.dropdown__content__item {
    margin-bottom: 0.7rem;
    color:#d7d1c0;
}

.dropdown__map {
    width: 100%;
}

.dropdown__map__controls {
    position: absolute;
    top: 0.5rem;
    z-index: 10;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dropdown__map__controls button {
    color: white;
    opacity: 0.9;
    border: none;
    background: hsl(230, 28%, 45%, 0.9);
    border-radius: 50%;
    margin: 0.1rem;
    width: 1.75rem;
    height: 1.75rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

}

.dropdown__map__controls__left {
    left: 0.5rem;
}
.dropdown__map__controls__right {
    right: 0.5rem;
}

/* Mobile breakpoints */
    @media only screen and (max-width: 850px) {
    .dropdown__header__caret {
        left: 1rem;
    }
    .dropdown__content__item {
        list-style: none;
    }
    .dropdown__header_title {
        font-size: 1rem;
    }
    .dropdown__header__title h3 {
        font-size: 1rem;
        margin-left: 2.5rem;
        margin-right: 2.5rem;
        text-wrap: pretty; /* Makes spacing look more natural on smaller screens */
    }
}

@media only screen and (max-width: 675px) {
    .events .dropdown__header__title h3 {
        margin: 1rem 1rem 1rem 4rem;
    }
    .dropdown__header__caret {
        left: 0.5rem;
    }
    .dropdown__header__title h3 {
        margin-left: 2rem;
        margin-right: 2rem;
        /* Wanted to decrease right-margin for more space for text but then it wouldn't be centered */
    }
}
