.card {
    background: #525d92;
    border-radius: 1rem;
    box-sizing: border-box;
    box-shadow: 0.75rem 0.75rem 0 rgba(0, 0, 0, 0.3);
    max-width: 25rem;
    min-height: 25rem;    
    transition: 0.1s ease-in;
}

.card:hover {
    transform: scale(1.03);
}

.card__header {
    background: #24347b;
    margin: 0;
    width: 100%;
    border-radius: 1rem 1rem 0 0;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card__header__title {
    margin: 0;
    color: #fcb839;
}

.card__content {
    margin: 1rem;
    color: #d7d1c0;
    line-height: 1.4;
}


@media only screen and (max-width: 600px) {
    .card {
        font-size: 0.9rem;
        min-height: 15rem;
    }
    .card__content {
        line-height: 1.3;
    }
}