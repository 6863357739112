.resources {
    padding-left: min(10vw, 5rem);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.resources h1 {
    margin: 1rem 0 0.5rem 0
}

.resources h2 {
    text-align: center;
}

.resources p {
    margin: 0.5rem 0;
    text-align: center;  
    width: 100%;
    /* to match the width of the dropdowns: */
    max-width: 50rem; 
}

hr {
    width: 100%;
    max-width: 50rem;
    margin: 1rem 0;
}