.multiselect {
    display: flex;
    flex-direction: column;
}

.multiselect label {
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.multiselect input[type="checkbox"] {
    margin-right: 0.5rem;
    width: 1rem;
    height: 1rem;
    cursor: pointer;
    accent-color: #FCB839; /* Change the checkbox checked background colour */
}

.multiselect input[type="checkbox"]:hover {
    box-shadow: 0 0 2px hsl(229, 55%, 31%, 0.3);
}

.multiselect input[type="checkbox"]:checked {
    outline: 1px solid rgba(36, 52, 123, 0.5);
}
