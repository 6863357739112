.filter__dropdown {
    overflow: hidden;
    width: 100%;
    max-width: 50rem;
    margin-bottom: 1rem;
}

/* Header */
.filter__dropdown__header {
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.filter__dropdown__header:hover h3 {
    color: hsl(229, 55%, 30%);
    text-decoration: underline;
}

.filter__dropdown__header__title h3 {
    color: hsl(229, 55%, 31%, 0.8);
    font-style: italic;
}

/* Content */
.filter__dropdown__content {
    background-color: hsl(229, 13%, 95%);
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border-radius: 1.5rem;
}

.filter__dropdown__content.closed-ul {
    max-height: 0;
    overflow: hidden;
    transition: max-height 2s cubic-bezier(0, 2, 1, 1);
}

.filter__dropdown__content.open-ul {
    max-height: 80rem; /*Because css is funky I have to set a max-height greater than the list will ever get for the transition property to work*/
    margin: 0;
    transition: max-height 0.5s ease-in;
}

.filter__dropdown__content__item {
    color: hsl(44, 22%, 35%);
    margin-top: 0.5rem;
}

.filter__options {
    padding: 1rem 7% 1rem 7%;
    margin: 0;
}

.filter__options input[type="text"] {
    width: 100%;
    padding: 0.5rem;
    margin: 0 0 0.5rem 0;
    box-sizing: border-box;
    border-radius: 0.5rem;
    border: 1px solid #24347b;
    font-size: 1rem;
}

.multiselect__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

/* Mobile breakpoints */
@media only screen and (max-width: 850px) {
    .filter__dropdown__header__caret {
        left: 1rem;
    }
    .filter__dropdown__content__item {
        list-style: none;
    }
    .filter__dropdown__header_title {
        font-size: 1rem;
    }
}

@media only screen and (max-width: 675px) {
    .filter__dropdown__header__title h3 {
        font-size: 1rem;
        margin-left: 3rem;
        margin-right: 3rem;
    }
    .events .filter__dropdown__header__title h3 {
        margin: 1rem 1rem 1rem 4rem;
    }
}